<template>
  <NuxtPage />
</template>

<script setup>
const colorMode = useColorMode()
</script>
<style lang="scss">
@import "~/assets/scss/auth.scss";
</style>


